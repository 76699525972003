import * as types from './auth.types'
import { getOnStorage } from 'utils/storage'

const authStorage = getOnStorage('auth.auth') 
const userStorage = authStorage && getOnStorage('auth.user')
const roleStorage = authStorage && userStorage && getOnStorage('auth.role')

const initialState: AuthState = {
    auth: authStorage || {},
    user: userStorage || {},
    roles: [],
    rolePaths: {},
    currentRole: roleStorage || {},
    permissions: {}
}

export default function state(state = initialState, action: any) {
    switch (action.type) {
        case types.SET_AUTH:
            return {
                ...state,
                auth: action.payload.auth
            }
        case types.SET_AUTH_USER:
            return {
                ...state,
                user: action.payload.user
            }
        case types.SET_USER_ROLES:
            return {
                ...state,
                roles: action.payload.roles
            }
        case types.SET_USER_SELECTED_ROLE:
            return {
                ...state,
                currentRole: action.payload.role
            }
        case types.SET_USER_ROLE_PATHS:
            return {
                ...state,
                rolePaths: action.payload.paths
            }
        case types.SET_USER_ROLE_PERMISSIONS:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    [`${action.payload.roleId}`]: action.payload.permissions
                }
            }
        case types.CLEAR_DATA:
            return {
                auth: {},
                user: {},
                roles: [],
                rolePaths: {},
                currentRole: {}
            }
        default:
            return state
    }
}