import * as types from './device.types'

const initialState: DeviceState = {
    inactive: {},
    unassigned: {
        esensor: []
    },
    forApproval: {},
    images: {}
}

export default function state(state = initialState, action: any): DeviceState {
    
    switch (action.type) {
        case types.SET_UNASSIGNED_ESENSOR_DEVICES:
            return {
                ...state,
                unassigned: {
                    ...state.unassigned,
                    esensor: action.payload.devices
                }
            }

        case types.SET_INACTIVE_NETWORK_DEVICE:
            return {
                ...state,
                inactive: {
                    ...state.inactive,
                    [`${action.payload.companyId}`]: action.payload.devices
                }
            }
        case types.SET_FOR_APPROVAL_NETWORK_DEVICE:
            return {
                ...state,
                forApproval: {
                    ...state.forApproval,
                    [`${action.payload.companyId}`]: action.payload.devices
                }
            }

        case types.SET_NETWORK_DEVICE_IMAGES:
            return {
                ...state,
                images: {
                    ...state.images,
                    [`${action.payload.deviceSerialId}`]: action.payload.images
                }
            }

        default:
            return state
    }
}