export const SET_NETWORK_PARTNERS = 'network:setNetworkPartners'
export const SET_NETWORK_PARTNER_BILLING = 'network:setNetworkPartnerBilling'
export const SET_NETWORK_LIST = 'network:setNetworkList'
export const SET_NETWORK_DETAILS = 'network:setNetworkDetails'
export const SET_NETWORK_LIVE_DATA = 'network:setNetworkLiveData'
export const SET_NETWORK_RECENT_LIVE_DATA = 'network:setNetworkRecentLiveData'
export const SET_NETWORK_VOLT_HISTORY = 'network:setNetworkVoltHistory'
export const SET_NETWORK_VOLT_HISTORY_LAST_REQUEST = 'network:setNetworkVoltHistoryLastRequest'
export const SET_NETWORK_VOLT_SUMMARY = 'network:setNetworkVoltSummary'
export const SET_NETWORK_VOLT_HISTOGRAM = 'network:setNetworkVoltHistogram'
export const SET_NETWORK_GRAPH_DATA = 'network:setNetworkGraphDate'
export const SET_NETWORK_EVENT_HISTORY = 'network:setNetworkEventHistory'
export const SET_NETWORK_PARTNER_WEATHER_FORECAST = 'network:setNetworkPartnerWeatherForecast'
export const SET_NETWORK_LOADING_PROFILE_30_DAYS = 'network:setNetworkLoadingProfile30Days'
export const SET_NETWORK_SUMMARY = 'network:setNetworkSummary'
export const SET_NETWORK_SITE_DATA_DELIVERY_STATUS = 'network:setNNetworkSiteDataDeliveryStatus'