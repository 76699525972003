import * as types from './status.types'

const initialState = {
    server: {},
}

export default function state(state = initialState, action: any) {
    switch (action.type) {
        case types.SET_STATUS:
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        case types.CLEAR_STATUS:
            return {
                ...state,
                [action.payload.key]: undefined
            }


        case types.SET_SERVER_STATUS:
            return {
                ...state,
                server: action.payload.status
            }
        
        default:
            return state
    }
}