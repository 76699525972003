import moment from 'moment'
import * as types from './commercial.types'

const initialState: CommercialState = {
    groups: {},
    details: {},
    consumption: {},
    consumptionDetails: {},
    sustainability: {},
    billing: {},
    siteGraph: {},
    subsites: {}, // architecture
    deviceChannelConfig: {}, // device channel config
}

export default function state(state=initialState, action: any): CommercialState {
    switch(action.type) {
        case types.SET_COMMERCIAL_GROUP_LISTING:
            const key = `${action.payload.roleId}_${action.payload.email}`

            return {
                ...state,
                groups: {
                    ...state.groups,
                    [key]: action.payload.groups,
                    [`_${key}`]: { updated: moment().unix() }
                }
            }
        
        case types.SET_COMMERCIAL_GROUP_DETAILS:
            return {
                ...state,
                details: {
                    ...state.details,
                    [`${action.payload.portfolioId}`]: action.payload.portfolio,
                },
                _details: {
                    ...(state._details || {}),
                    [`_${action.payload.portfolioId}`]: { updated: moment().unix() }
                }
            }
        
        case types.SET_COMMERCIAL_SITE_SUBSITES:
            return {
                ...state,
                subsites: {
                    ...state.subsites,
                    [`${action.payload.siteId}`]: action.payload.subsites
                }
            }


        case types.SET_COMMERCIAL_GROUP_CONSUMPTION:
            const { portfolioId, siteKey, dateKey, consumption } = action.payload
            const portfolioState = state.consumption[`${portfolioId}`] || {}
            const siteState = portfolioState[`${siteKey}`] || {}

            return {
                ...state,
                consumption: {
                    ...state.consumption,
                    [`${portfolioId}`]: {
                        ...portfolioState,
                        [`${siteKey}`]: {
                            ...siteState,
                            [`${dateKey}`]: consumption
                        }
                    }
                }
            }

        case types.SET_COMMERCIAL_GROUP_CONSUMPTION_DETAILS:
            const consumptionDetailsPortfolioState = state.consumptionDetails[`${action.payload.portfolioId}`] || {}

            return {
                ...state,
                consumptionDetails: {
                    ...state.consumptionDetails,
                    [`${action.payload.portfolioId}`]: {
                        ...consumptionDetailsPortfolioState,
                        [`${action.payload.siteKey}`]: action.payload.details
                    }
                }
            }


        case types.SET_COMMERCIAL_GROUP_BILLING:
            const billingPortfolioState = state.billing[`${action.payload.portfolioId}`] || {}
            const billingSiteState = billingPortfolioState[`${action.payload.siteKey}`] || {}

            return {
                ...state,
                billing: {
                    ...state.billing,
                    [`${action.payload.portfolioId}`]: {
                        ...billingPortfolioState,
                        [`${action.payload.siteKey}`]: {
                            ...billingSiteState,
                            [`${action.payload.dateKey}`]: action.payload.billing
                        }
                    }
                }
            }

        case types.SET_COMMERCIAL_GROUP_SUSTAINABILITY:
            return {
                ...state,
                sustainability: {
                    ...state.sustainability,
                    [`${action.payload.portfolioId}`]: action.payload.sustainability
                }
            }

        case types.SET_COMMERCIAL_SITE_GRAPH_DATA:
            return {
                ...state,
                siteGraph: {
                    ...state.siteGraph,
                    [`${action.payload.siteId}`]: {
                        ...state.siteGraph[`${action.payload.siteId}`] || {},
                        [action.payload.dateKey]: action.payload.graph
                    }
                }
            }

        case types.SET_COMMERCIAL_SITE_DEVICE_CHANEL_CONFIG_LIST:
            return {
                ...state,
                deviceChannelConfig: {
                    [`${action.payload.siteId}`]: action.payload.deviceChannelConfig
                }
            }
        
        default:
            return {...state}
    }
}