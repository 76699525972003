import { applyMiddleware, createStore, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle';

const reducers = combineReducers({
    auth: require('states/auth').default,
    commercial: require('states/commercial').default,
    residential: require('states/residential').default,
    network: require('states/network').default,
    device: require('states/device').default,
    partner: require('states/partner').default,
    status: require('states/status').default
 })
 

let store = createStore(
   reducers,
   {},
   compose(applyMiddleware(thunk))
)

store.subscribe(throttle(() => {
   const currentState: any = store.getState()
   localStorage.setItem('auth.auth', JSON.stringify(currentState.auth.auth))
   localStorage.setItem('auth.user', JSON.stringify(currentState.auth.user))
   localStorage.setItem('auth.role', JSON.stringify(currentState.auth.currentRole))
 }, 1000)); 



export default store