import { createTheme } from '@mui/material/styles';

export default createTheme({
    typography: {
        fontFamily: 'Muli'
    },
    colors: {
        pageBackground: '#f5f5f5',
        primary: '#039be5',
        secondary: '#f07f30',
        purple: '#8996CB',
        lightGreen: '#BCCF00',
        darkred: '#cd5c5c'
    },

    statusColor: {
        danger: '#f44336'
    },

    palette: {
        primary: {
            main: '#039be5'
        },
        secondary: {
            main: '#f07f30'
        },
        error: {
            main: '#cd5c5c'
        },
        purple: {
            main: '#8996CB'
        },
        lightGreen: {
            main: '#BCCF00'
        },
        red: {
            main: '#FF1212'
        },
        darkred: {
            main: '#cd5c5c'
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)'
        },
    },
    
})

/**
 * Add additional for palette
 */
declare module '@mui/material/styles/createPalette' {
    interface Palette {
        purple: Palette['primary'],
        lightGreen: Palette['primary'],
        red: Palette['primary'],
        darkred: Palette['primary']
    }
    interface PaletteOptions {
        purple: PaletteOptions['primary'],
        lightGreen: PaletteOptions['primary'],
        red:  PaletteOptions['primary'],
        darkred: PaletteOptions['primary']
    }
}

declare module '@mui/material/styles/createTheme' {
    interface Theme {
        colors: {
            pageBackground: React.CSSProperties['color'],
            primary: React.CSSProperties['color'],
            secondary: React.CSSProperties['color'],
            purple: React.CSSProperties['color'],
            lightGreen: React.CSSProperties['color'],
            darkred: React.CSSProperties['color']
        }
        statusColor: {
            danger: React.CSSProperties['color'],
        }
    }
    interface ThemeOptions {
        colors: {
            pageBackground: React.CSSProperties['color'],
            primary: React.CSSProperties['color'],
            secondary: React.CSSProperties['color'],
            purple: React.CSSProperties['color'],
            lightGreen: React.CSSProperties['color'],
            darkred: React.CSSProperties['color']
        }
        statusColor: {
          danger: React.CSSProperties['color']
        }
    }
}

  