import * as types from './network.types'
import moment from 'moment'

const initialState: NetworkState = {
    partners: [],
    billing: {},

    list: [],
    site: {},
    live: {},
    networkSummary: {},
    recentLive: {},
    loadingProfile30Days: {},
    voltHistory: {},
    voltHistogram: {},
    voltSummary: {},
    eventHistory: {},
    graphData: {},
    dataDeliveryStatus: {},

    partnerWeatherForecast: {}
}

export default function state(state = initialState, action: any): NetworkState {

    switch (action.type) {
        case types.SET_NETWORK_PARTNERS:
            return {
                ...state,
                partners: action.payload.partners
            }
        case types.SET_NETWORK_PARTNER_BILLING:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    [`${action.payload.partnerId}`]: action.payload.billing
                }
            }

        case types.SET_NETWORK_LIST:
            return {
                ...state,
                list: action.payload.list
            }
        case types.SET_NETWORK_PARTNER_WEATHER_FORECAST:
            return {
                ...state,
                partnerWeatherForecast: {
                    ...state.partnerWeatherForecast,
                    [action.payload.partner]: action.payload.forecast
                }
            }
        case types.SET_NETWORK_LIVE_DATA: {
            return {
                ...state,
                live: {
                    ...state.live,
                    [action.payload.deviceApiId]: {
                        timestamp: action.payload.timestamp,
                        data: action.payload.data
                    }
                }
            }
        }

        case types.SET_NETWORK_SUMMARY:
            return {
                ...state,
                networkSummary: {
                    ...(state.networkSummary || {}),
                    [action.payload.deviceSerialId]: action.payload.data
                }
            }

        case types.SET_NETWORK_DETAILS: {
            return {
                ...state,
                site: {
                    ...state.site,
                    [action.payload.deviceSerialId]: action.payload.site
                }
            }
        }

        case types.SET_NETWORK_LOADING_PROFILE_30_DAYS: {
            return {
                ...state,
                loadingProfile30Days: {
                    ...state.loadingProfile30Days,
                    [action.payload.deviceSerialId]: action.payload.loadingProfile
                }
            }
        }

        case types.SET_NETWORK_RECENT_LIVE_DATA: 
            return {
                ...state,
                recentLive: {
                    ...state.recentLive,
                    [action.payload.deviceSerialId]: action.payload.data
                }
            }

        case types.SET_NETWORK_VOLT_HISTORY:
            return {
                ...state,
                voltHistory: {
                    ...state.voltHistory,
                    [action.payload.deviceSerialId]: {
                        ...(state.voltHistory[action.payload.deviceSerialId] || {}),
                        [action.payload.dateKey]: action.payload.data
                    }
                },
                _voltHistory: { lastUpdated: moment().unix(), lastDateKeyRequest: action.payload.dateKey }
            }

        case types.SET_NETWORK_VOLT_HISTORY_LAST_REQUEST:
            return {
                ...state,
                _voltHistory: {
                    ...(state._voltHistory || {}),
                    lastDateKeyRequest: action.payload.dateKey
                }
            }

        case types.SET_NETWORK_VOLT_HISTOGRAM:
            return {
                ...state,
                voltHistogram: {
                    ...state.voltHistogram,
                    [action.payload.deviceApiId]: {
                        ...(state.voltHistogram[action.payload.deviceApiId] || {}),
                        [action.payload.dateKey]: action.payload.data
                    }
                }
            }
        
        case types.SET_NETWORK_VOLT_SUMMARY:
            return {
                ...state,
                voltSummary: {
                    ...state.voltSummary,
                    [action.payload.deviceSerialId]: {
                        ...(state.voltSummary[action.payload.deviceSerialId] || {}),
                        [action.payload.dateKey]: action.payload.data
                    }
                }
            }
        
        case types.SET_NETWORK_EVENT_HISTORY:
            // only keep the last 5 queries
            let currentEventState = (state.eventHistory[action.payload.deviceSerialId] || {})
            let currentQueries = (state.eventHistory[action.payload.deviceSerialId] || {})['queries'] || []
            let updatedQueries = currentQueries.length >= 5
                ? [...currentQueries.splice(1)]
                : [...currentQueries]

            return {
                ...state,
                eventHistory: {
                    ...state.eventHistory,
                    [action.payload.deviceSerialId]: {
                        ...updatedQueries.reduce((state, dateKey) => {
                            return {...state, [dateKey]: currentEventState[dateKey]}
                        }, {}),
                        queries: [...updatedQueries, action.payload.dateKey],
                        [action.payload.dateKey]: action.payload.data
                    }
                }
            }
        
        case types.SET_NETWORK_GRAPH_DATA:
            return {
                ...state,
                graphData: {
                    ...state.graphData,
                    [action.payload.deviceSerialId]: action.payload.data
                }
            }

        case types.SET_NETWORK_SITE_DATA_DELIVERY_STATUS:
            return {
                ...state,
                dataDeliveryStatus: {
                    ...state.dataDeliveryStatus,
                    [`${action.payload.deviceApiId}`]: {
                        ...(state.dataDeliveryStatus[action.payload.deviceApiId] || {}),
                        [action.payload.dateKey]: action.payload.data
                    }
                }
            }

        default:
            return state
    }
}