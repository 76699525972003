import * as types from './residential.types'

const initialState: ResidentialState = {
    tabs: {},
    live: {},
    site: {},
    components: {},

    computed: {}
}

export default function residential(state = initialState, action: any): ResidentialState {
    switch (action.type) {
        case types.SET_RESIDENTIAL_TAB_SITES:
            return {
                ...state,
                tabs: action.payload.tabs
            }

        case types.SET_RESIDENTIAL_LIVE_DATA:
            return {
                ...state,
                live: {
                    ...state.live,
                    [action.payload.siteId]: action.payload.live
                }
            }

        case types.SET_RESIDENTIAL_SITE_DETAILS:
            return {
                ...state,
                site: {
                    ...state.site,
                    [action.payload.siteId]: action.payload.site
                }
            }

        case types.SET_RESIDENTIAL_COMPONENTS:
            return {
                ...state,
                components: {
                    ...state.components,
                    [action.payload.siteId]: action.payload.components
                }
            }

        default:
            return state
    }
}

