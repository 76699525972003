
export function saveOnStorage(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
}

export function getOnStorage(key: string) {
    const strItem = localStorage.getItem(key)
    if (!strItem) return false

    try {
        return JSON.parse(strItem)
    } catch (err) {
        return false
    }
}