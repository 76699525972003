import React from 'react';

import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'

import store from './store'
import Theme from './Theme'
import LinearProgress from '@mui/material/LinearProgress';

const AuthRoutes = React.lazy(() => import('app/auth/auth.routes'))
const AccountActivationRoutes = React.lazy(() => import('app/account-activation/account-activation.routes'))
const FleetSelectionPage =  React.lazy(() => import('pages/FleetSelectionPage/FleetSelectionPage'))
const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'))
const LogoutPage = React.lazy(() => import('pages/LogoutPage/LogoutPage'))

const ResetPasswordPage = React.lazy(() => import('app/auth/pages/ResetPasswordPage/ResetPasswordPage'))


function App() {
    return (
        <Provider store = {store}>
            <React.Suspense fallback = {<LinearProgress />}>
                <ThemeProvider theme = {Theme}>
                    <Router>
                        <Switch>
                            <Route path = "/reset-password">
                                <ResetPasswordPage />
                            </Route>
                            <Route path = "/">
                                <AccountActivationRoutes />
                            </Route>
                        </Switch>
                    </Router>
                </ThemeProvider>
            </React.Suspense>
        </Provider>
    );
}

export default App;
