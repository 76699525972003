import * as types from './partner.types'

const initialState: PartnerState = {
    partnerUsers: {},
    labels: {}
}

export default function partner(state=initialState, action: any): PartnerState {
    switch (action.type) {
        case types.SET_PARTNER_USERS:
            return {
                ...state,
                partnerUsers: {
                    ...state.partnerUsers,
                    [action.payload.partner]: action.payload.users
                }
            }
        case types.SET_PARTNER_LABELS:
            return {
                ...state,
                labels: {
                    ...state.labels,
                    [`${action.payload.partner}`]: action.payload.labels
                }
            }
        default:
            return state
    }
}